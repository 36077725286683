import { template as template_e3738f6c050e4f2281f3d2faf420f33f } from "@ember/template-compiler";
const FKLabel = template_e3738f6c050e4f2281f3d2faf420f33f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
