import { template as template_d25caa0af3434530a1e8afa8bc1d9323 } from "@ember/template-compiler";
const FKText = template_d25caa0af3434530a1e8afa8bc1d9323(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
