import { template as template_02f551e78cbb480a8e2934e12232700e } from "@ember/template-compiler";
const FKControlMenuContainer = template_02f551e78cbb480a8e2934e12232700e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
